import React from 'react'
import PropTypes from 'prop-types'
import Price from 'components/misc/Price'

import BookablePriceDescription from './BookablePriceDescription'

const BookableGuestPrice = ({ currency, guests, pickedPriceType }) => {
  return (
    <>
      {guests.guestTypes
        .filter(({ amount }) => amount > 0)
        .map(({ guestType }) => (
          <BookablePriceDescription key={guestType.guestTypeId}>
            <Price
              amount={guestType.price || pickedPriceType.amount || 0}
              currency={currency}
            />{' '}
            {guestType.labels.singular}
          </BookablePriceDescription>
        ))}
    </>
  )
}

BookableGuestPrice.propTypes = {
  currency: PropTypes.string,
  guests: PropTypes.object,
  pickedPriceType: PropTypes.object
}

BookableGuestPrice.displayName = 'BookableGuestPrice'

export default BookableGuestPrice
